import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/home";
import { Layout } from "./components/layout";
// import About from "./components/pages/about";
import License from "./components/pages/license";
import Services from "./components/pages/services";
import Specialists from "./components/pages/specialists";
import Contacts from "./components/pages/contacts";
import OrganizationInfo from "./components/pages/organizationInfo";
import MedicalActivities from "./components/pages/medicalActivities";
import PatientsInformation from "./components/pages/patientsInformation";
import Rules from "./components/pages/rules";
import SpecialistsInfo from "./components/pages/specialistsInfo";
import Specialist from "./components/pages/specialist";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/about" element={<About />} /> */}
          <Route exact path="/license" element={<License />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/specialists" element={<Specialists />} />
          <Route exact path="/specialists-info" element={<SpecialistsInfo />} />
          <Route exact path="/specialist/:id" element={<Specialist />} />
          <Route exact path="/contacts" element={<Contacts />} />
          <Route exact path="/organization-info" element={<OrganizationInfo />} />
          <Route exact path="/medical-activities" element={<MedicalActivities />} />
          <Route exact path="/patients-information" element={<PatientsInformation />} />
          <Route exact path="/rules" element={<Rules />} />
          <Route exact path="*" element={<Home />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
