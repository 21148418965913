export const rulesBlocks = [
  {
    title: "Уважаемые пациенты!",
    underlinedText: [
      "Достоверность результатов исследований во многом зависит от подготовки пациента. Чтобы получить максимально точные данные о состоянии Вашего здоровья, просим соблюдать правила подготовки к исследованиям и сбора биологического материала.",
    ],
  },
  {
    title: "УЗИ пищеварительного тракта и органов брюшной полости",
    text: [
      "Не менее чем за три дня до назначенной даты УЗИ следует исключить из рациона продукты, способствующие повышенному газообразованию в кишечнике. Это черный хлеб, любое молоко, свежие фрукты и овощи.",
      "При повышенном газообразовании необходимо загодя, примерно за 12 часов до исследования принять не менее пяти таблеток активированного угля. Угольные таблетки нужно полностью разжевывать и запивать достаточным количеством воды. Можно растереть уголь в порошок, залить водой (не более 100 мл), перемешать и выпить.",
      "Допускается использование иных энтеросорбентов. Помогает прием «Эспумизана». Две или три мерных ложки лекарства избавляют от метеоризма на несколько часов.",
      "Процедура ультразвукового исследования брюшной полости должна проводиться не менее чем через 6 часов после приема пищи. Детям достаточно не есть 4 часа. Курение, употребление напитков и жевательной резинки запрещены: рефлекторные реакции желчного пузыря могут исказить трактовку результатов УЗИ.",
      'Пациентам с сахарным диабетом голодный период максимум 2-3 часа, по возможности, между приемами пищи. При "остром" животе УЗИ проводится без подготовки.',
    ],
  },
  {
    title: "Холецистометрия (определение функции желчного пузыря)",
    text: [
      "Проводится детям и взрослым только по назначению врача с указанием направившим врачом вида и количества пробного завтрака (яйца, шоколад, банан, то есть то, что скажет доктор и в каком количестве). Пациенты приходят строго натощак. После осмотра съедают завтрак и проводится два исследования с интервалом времени.",
    ],
  },
  {
    title: "УЗИ мочевого пузыря",
    text: [
      "При исследованиях мочевого пузыря, яичников и матки с помощью ультразвука для достижения лучшей видимости органов перед началом процедуры необходимо иметь полный мочевой пузырь. Для этого за 2 часа до начала исследования рекомендуется выпить около 2-х литров жидкости. Второй путь - наполнять пассивно, то есть при обычном питьевом режиме 2-3 часа не мочиться. Точно также готовятся к УЗИ предстательной железы и мочевого пузыря. Процедура длится от пяти до двадцати минут и не оказывает негативного влияния на организм, т. к. ультразвук абсолютно безопасен. Ограничений по приему пищи нет.",
    ],
  },
  {
    title: "УЗИ молочных желез",
    text: [
      "Не требует специальной подготовки, однако обычно проводится в промежуток между пятым и двенадцатым днями менструального цикла.",
    ],
  },
  {
    title: "УЗИ предстательной железы трансабдоминально",
    text: [
      "Утром в день обследования необходимо опорожнить кишечник. Непосредственно перед исследованием освободить мочевой пузырь. УЗИ через переднюю поверхность брюшной стенки проводится на полный мочевой пузырь. За 1,5 часа до начала процедуры нужно не спеша выпить до полутора литров воды, чая, сока и т. п., так, чтобы к назначенному моменту мочевой пузырь наполнился. Или наполнять пассивно, то есть при обычном питьевом режиме 2-3 часа не мочиться. Есть в день исследования можно как обычно.",
    ],
  },
  {
    title: "ТРУЗИ",
    text: [
      "Трансректальное УЗИ – утром перед обследованием опорожнить кишечник. Перед исследованием опорожнить мочевой пузырь.",
    ],
  },
  {
    title: "УЗИ органов малого таза у женщин",
    text: [
      "Обычный срок проведения ультразвукового исследования – с пятого по седьмой день менструального цикла. Лечащий врач может назначить другой день. При трансвагинальной методике исследования необходим порожний мочевой пузырь. УЗИ трансабдоминально (через переднюю поверхность брюшной стенки) – проводится с наполненным мочевым пузырем. Для достижения лучшего эффекта пациентка должна за 1.5 часа до исследования начать принимать любые напитки с тем, чтобы к назначенному времени иметь полный мочевой пузырь, либо должна 2-3 часа не мочиться. Предпочтительны слабозаваренный чай, вода, соки и прочие жидкости, не обладающие мочегонным эффектом. Прием пищи не ограничивается.",
    ],
    underlinedText: [
      "УЗИ других органов и систем специальной подготовки не требует.",
    ],
  },
  {
    title: "Правила подготовки к лабораторным исследованиям",
    text: [
      "Общие требования по взятию биологического материала для лабораторных исследований:",
    ],
  },
  {
    title: "Кровь:",
  },
  {
    title: "Как подготовиться к анализу крови?",
    text: [
      "Забор крови проводится в нашей лаборатории утром натощак. Сок, чай, кофе – тоже еда! Последний прием пищи возможен накануне вечером. Не рекомендуется отправляться сдавать кровь для анализа после тяжелой физической нагрузки, внутримышечного или внутривенного введения медикаментов, проведения физиотерапевтических процедур и воздействия на пациента рентгеновских лучей. При соблюдении всех требований биохимический анализ крови даст наиболее достоверные результаты.",
    ],
  },
  {
    title: "Как подготовиться к анализу крови на гормоны?",
    text: [
      "Забор крови на гормоны, требует ещё большей ответственности от пациента. Кроме обычного требования - сдачи крови натощак - не рекомендуется пить, курить утром до сдачи анализа, а так же употреблять жирную острую и сладкую пищу накануне вечером. Для некоторых исследований важен также психологический и половой покой в течении трёх дней, предшествующих забору материала.",
    ],
  },
  {
    title: "Моча:",
  },
  {
    title: "Общие правила подготовки к сбору мочи",
    text: [
      "Перед сбором мочи проводят тщательный туалет наружных половых органов. Для получения достоверных результатов Вам необходимо воздержаться от физических нагрузок, приема алкоголя, лечь спать накануне в обычное для Вас время.",
      "Нельзя собирать мочу во время менструации. После проведения цистоскопии анализ мочи можно назначать не ранее, чем через 5-7 дней.",
    ],
  },
  {
    title: "Общий анализ мочи",
    text: [
      "Собирается вся утренняя моча (первое мочеиспускание после сна) в чистую стеклянную посуду или контейнер. Если объем мочи слишком велик, можно из общего количества сразу отлить 100-200 мл и принести в лабораторию. Исследование меньшего количества может привести к получению менее достоверных результатов.",
    ],
  },
  {
    title: "Правила сбора суточной мочи",
    text: [
      "Моча собирается в течение суток. Первая утренняя порция мочи исключается. Все последующие порции мочи, выделенные в течение дня, ночи и утренняя порция следующего дня собираются в емкость, которая хранится в холодильнике (+2 - +8) в течение всего времени сбора. По окончании сбора точно (до 5 мл) измерить объем выделенной за сутки мочи (диурез), обязательно перемешать и сразу же отлить в другую емкость небольшое количество (около 20 мл.) мочи. Именно эту емкость доставить в лабораторию.",
    ],
  },
  {
    title: "Исследование мочи по Нечипоренко",
    text: [
      "Исследуется средняя порция утренней мочи, при сборе мочи придерживайтесь общих рекомендации.",
    ],
  },
  {
    title: "Исследование мочи по Зимницкому",
    text: [
      "Используется 8 емкостей для сбора мочи. Предварительно напишите на этикетке на емкостях для мочи Ваши данные: Ф.И.О., дату рождения, дату и время сбора мочи: 9, 12, 15, 18, 21, 24, 3 и 6 часов утра следующих суток. Утром в 6 часов Вы должны полностью опорожнить мочевой пузырь. Затем через каждые 3 часа (в 9, 12, 15, 18, 21, 24, 3 и 6) собираете всю мочу, которая накопилась у Вас за это время, в отдельную предварительно подписанную емкость. Каждую емкость завинчиваете крышкой и ставите в прохладное темное место. На следующий день после последнего сбора мочи Вы доставляете все емкости для исследования.",
    ],
  },
  {
    title: "Сбор мокроты",
    text: [
      "Сбор мокроты для исследования осуществляется в домашних условиях, для сбора и транспортировки необходимо использовать только одноразовый контейнер. Сбору подлежит утренняя мокрота, полученная при кашле, перед сбором необходима тщательная гигиена ротовой полости и полоскание кипяченой водой. Недопустимо попадание в мокроту слюны и носоглоточной слизи! При плохо отделяемой мокроте допустим прием отхаркивающих средств накануне исследования.",
    ],
  },
  {
    title: "Правила сбора кала",
  },
  {
    title:
      "Кал на общий анализ, обнаружение яиц гельминтов и на скрытую кровь, копрология:",
    text: [
      "Материал собирается в чистую стеклянную или пластиковую посуду (контейнер) в объеме, равном 1/2 чайной ложки из различных мест разовой порции в количестве не более 1/3 объема контейнера. Для достоверного определения скрытой крови необходимо за 3 дня до анализа исключить мясо, рыбу, зеленые овощи, помидоры и лекарства, содержащие металлы (железо, медь). Следует избегать примеси мочи и выделений из половых органов.",
      "Необходимо по возможности отменить прием медикаментов, влияющих на секреторные процессы и перистальтику (слабительные, ферменты, симпатомиметики, препараты железа), а также мешающих проведению исследования (ректальные свечи). Исследование не информативно у пациентов с кровотечениями (геморрой, длительные запоры, заболевания десен с признаками кровоточивости, менструации).",
      "Материал должен быть доставлен в лабораторию не позднее, чем 8-12 часов после дефекации. Хранить до исследования можно в холодильнике при 3-5 Сº. Кроваво-слизистый, слизистый и жидкий стул должен быть обследован непосредственно после дефекации, еще в теплом состоянии. При наличии в испражнениях патологических примесей (слизь, хлопья, гной) их следует включить в отбираемую пробу. Недопустимо направлять кал на исследование в спичечных или картонных коробках, так как при этом меняются форма и консистенция кала и искажаются результаты Ваших анализов.",
    ],
  },
  {
    title: "Взятие биологического материала из мочеполового тракта:",
    underlinedText: ["У женщин:"],
  },
  {
    text: [
      "Взятие биологического материала из мочеполового тракта у женщин должно производиться до назначения антимикробных препаратов либо не ранее чем через 10-14 дней после их отмены, в отсутствие менструации/кровянистых выделений, за 3 дня до исследования прекратить использование местных лечебных и контрацептивных средств, накануне исследования воздержаться от половых контактов.",
    ],
    underlinedText: ["У мужчин:"],
  },
  {
    text: [
      "Взятие биологического материала из мочеполового тракта у мужчин должно производиться до назначения антимикробных препаратов либо не ранее чем через 10-14 дней после их отмены, до первого утреннего мочеиспускания либо через 2 часа с момента последнего мочеиспускания.",
    ],
  },
  {
    title: "Кольпоскопия",
    text: [
      "Подготовка к кольпоскопии очень проста и не требует никаких специальных мер. Но есть список действий, которых, следует избегать в течение одного-двух дней до процедуры:",
    ],
    links: [
      "вагинальный половой акт;",
      "спринцевания и подмывания любыми средствами гигиены (допустима только теплая вода);",
      "применения лекарственных спреев, вагинальных свечей, мазей, тампонов.",
    ],
  },
  {
    text: [
      "При месячных процедура не проводится.Наиболее оптимальным временем, в который делают кольпоскопию, считается период от 3 до 7 дня по окончании менструальных выделений.Во время менопаузы разрешается делать кольпоскопию в любое время.",
    ],
  },
];
