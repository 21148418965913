import { Box, CardMedia, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import BoxWrapper from "../../common/boxWrapper";
import { useParams } from "react-router-dom";
import { specialistsData } from "../../../common/constants/specialists";
import Union from "../../../assets/images/Union.svg";
import ProfileOpenBlock from "../../common/profileOpenBlock";
import ProfileOpenBlockField from "../../common/profileOpenBlockField";

const Specialist = () => {
  const { id } = useParams();
  const [specialist, setSpecialist] = useState();
  useEffect(() => {
    const newSpecialist = specialistsData.find(
      (o) => o.id.toString() === id.toString()
    );
    setSpecialist(newSpecialist);
  }, [id]);
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <BoxWrapper
              sx={{
                background: "transparent",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "16px", md: "24px" },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: { xs: "32px", md: "40px" },
                    fontWeight: 600,
                    lineHeight: { xs: "40px", md: "48px" },
                    fontStyle: "italic",
                  }}
                >
                  {specialist?.surname}
                </Typography>
                <Typography
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: { xs: "32px", md: "40px" },
                    fontWeight: 600,
                    lineHeight: { xs: "40px", md: "48px" },
                  }}
                >
                  {specialist?.name} {specialist?.patronymic}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "18px", md: "24px" },
                  fontWeight: 600,
                  lineHeight: { xs: "26px", md: "32px" },
                  fontStyle: "italic",
                }}
              >
                {specialist?.post}
              </Typography>
            </BoxWrapper>
            <BoxWrapper
              sx={{
                background: "#E7E1E6",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <CardMedia
                component="img"
                image={Union}
                alt="Union"
                sx={{
                  position: "absolute",
                  m: "auto",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  textAlign: "center",
                  width: "auto",
                  height: "auto",
                  maxHeight: "100px",
                  flexShrink: 0,
                  opacity: 0.2,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "8px", md: "16px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "20px", md: "24px" },
                      fontStyle: "italic",
                    }}
                  >
                    Специальность:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "20px", md: "24px" },
                      textAlign: "end",
                    }}
                  >
                    {specialist?.specialize}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "20px", md: "24px" },
                      fontStyle: "italic",
                    }}
                  >
                    Квалификация:
                  </Typography>
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: 400,
                      lineHeight: { xs: "20px", md: "24px" },
                      textAlign: "end",
                    }}
                  >
                    {specialist?.qualification}
                  </Typography>
                </Box>
                {specialist?.category && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "16px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "12px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "20px", md: "24px" },
                        fontStyle: "italic",
                      }}
                    >
                      Квалификационная категория, звание, ученая степень:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "12px", md: "16px" },
                        fontWeight: 400,
                        lineHeight: { xs: "20px", md: "24px" },
                        textAlign: "end",
                      }}
                    >
                      {specialist?.category}
                    </Typography>
                  </Box>
                )}
              </Box>
            </BoxWrapper>
            <ProfileOpenBlock blockName={"Диплом"} open={true}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: { xs: "20px 0 0", md: "20px 32px 0 64px" },
                }}
              >
                <ProfileOpenBlockField value={specialist?.diploma} />
              </Box>
            </ProfileOpenBlock>
            <ProfileOpenBlock
              blockName={"Сертификаты / аккредитация"}
              open={false}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: { xs: "20px 0 0", md: "20px 32px 0 64px" },
                }}
              >
                {specialist?.certificate.map((certificate, index) => (
                  <ProfileOpenBlockField value={certificate} key={index} />
                ))}
              </Box>
            </ProfileOpenBlock>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default Specialist;
