import React from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import { Box, Grid, Typography } from "@mui/material";
import { specialistsData } from "../../../common/constants/specialists";
import { useNavigate } from "react-router-dom";

const SpecialistsInfo = () => {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Сведения о медицинских работниках ООО «Профи-клиника»
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {specialistsData.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Box
                    onClick={() => navigate(`/specialist/${item.id}`)}
                    sx={{
                      borderRadius: "24px",
                      background:
                        "radial-gradient(70.71% 70.71% at 50% 50%, #F8ECE9 31.89%, #DED6DC 80%)",
                      border: "1px solid transparent",
                      minHeight: { xs: "150px", md: "250px" },
                      height: "100%",
                      cursor: "pointer",
                      ":hover": {
                        border: "1px solid rgba(84, 98, 114, 0.5)",
                        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 1)",
                      },
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          p: "32px",
                          display: "flex",
                          flexDirection: "column",
                          gap: { xs: "8px", md: "14px" },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: 600,
                            lineHeight: { xs: "24px", md: "30px" },
                          }}
                        >
                          {item.surname} {item.name} {item.patronymic}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "16px" },
                            lineHeight: { xs: "22px", md: "24px" },
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                            // display: "-webkit-box",
                            // WebkitLineClamp: "2",
                            // WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.post}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "16px" },
                            lineHeight: { xs: "22px", md: "24px" },
                            // overflow: "hidden",
                            // textOverflow: "ellipsis",
                            // display: "-webkit-box",
                            // WebkitLineClamp: "2",
                            // WebkitBoxOrient: "vertical",
                          }}
                        >
                          {item.category}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default SpecialistsInfo;
