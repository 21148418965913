import React from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import { Box, Grid, Typography } from "@mui/material";
import BoxWrapper from "../../common/boxWrapper";
import { specialistsData } from "../../../common/constants/specialists";
// import { useNavigate } from "react-router-dom";

const Specialists = () => {
  // const navigate = useNavigate();
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Наши специалисты
              </Typography>
            </Box>
            <BoxWrapper
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  lineHeight: { xs: "24px", md: "26px" },
                  textAlign: "justify",
                }}
              >
                Уважаемые пациенты
              </Typography>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  lineHeight: { xs: "24px", md: "26px" },
                  textAlign: "justify",
                }}
              >
                Прием в нашей клинике проводится{" "}
                <b>только по предварительной записи!</b>
              </Typography>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 500,
                  lineHeight: { xs: "24px", md: "26px" },
                  textAlign: "justify",
                }}
              >
                В связи с тем, что большинство наших врачей являются внешними
                совместителями, их график приема может корректироваться в
                зависимости от дежурств по основному месту работы. Актуальный
                график приема узнавайте в регистратуре по тел.:{" "}
                <b>8 961-324-78-66</b> и <b>822-35-61</b>
              </Typography>
            </BoxWrapper>
            <Grid container spacing={3}>
              {specialistsData.map(
                (item) =>
                  item.specialist && (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                      <Box
                        sx={{
                          borderRadius: "24px",
                          background:
                            "radial-gradient(70.71% 70.71% at 50% 50%, #F8ECE9 31.89%, #DED6DC 80%)",
                          border: "1px solid transparent",
                          minHeight: { xs: "150px", md: "250px" },
                          height: "100%",
                          ":hover": {
                            border: "1px solid rgba(84, 98, 114, 0.5)",
                            boxShadow:
                              "0px 0px 16px 0px rgba(230, 230, 230, 1)",
                          },
                        }}
                      >
                        <Box
                        // sx={{ pt: "150px" }}
                        >
                          <Box
                            sx={{
                              p: "32px",
                              display: "flex",
                              flexDirection: "column",
                              gap: { xs: "8px", md: "14px" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "16px", md: "20px" },
                                fontWeight: 600,
                                lineHeight: { xs: "24px", md: "30px" },
                              }}
                            >
                              {item.surname} {item.name} {item.patronymic}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "16px" },
                                lineHeight: { xs: "22px", md: "24px" },
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                                // display: "-webkit-box",
                                // WebkitLineClamp: "1",
                                // WebkitBoxOrient: "vertical",
                              }}
                            >
                              {item.post}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "16px" },
                                lineHeight: { xs: "22px", md: "24px" },
                              }}
                            >
                              {item.timeTable}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default Specialists;
