import React from "react";
import { Box } from "@mui/material";

const BlockWrapper = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{ maxWidth: "1100px", width: "-webkit-fill-available", ...props.sx }}
    >
      {children}
    </Box>
  );
};

export default BlockWrapper;
