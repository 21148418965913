import { Box, Typography } from "@mui/material";
import React from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import {
  patientsInformationBlocks,
  patientsInformationLinks,
  phone,
} from "../../../common/constants";
import BoxWrapper from "../../common/boxWrapper";

const PatientsInformation = () => {
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Информация для пациентов
              </Typography>
            </Box>
            <BoxWrapper
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "32px", md: "40px" },
                  textAlign: "center",
                }}
              >
                ООО «Профи-клиника» оказывает услуги только на возмездной
                основе!
              </Typography>
              {patientsInformationBlocks.map((block, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "4px", md: "8px" },
                  }}
                >
                  {block.bold && (
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "16px", md: "22px" },
                        fontWeight: 600,
                        lineHeight: { xs: "24px", md: "30px" },
                        textAlign: "justify",
                      }}
                    >
                      {block.bold}
                    </Typography>
                  )}
                  {block.text &&
                    block.text.map((text, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textAlign: "justify",
                        }}
                      >
                        {text}
                      </Typography>
                    ))}
                  {block.textAndLinks &&
                    block.textAndLinks.map((item, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textDecoration: "none",
                          textIndent: "32px",
                          textAlign: "justify",
                        }}
                      >
                        {item.text}&nbsp;
                        <a href={`http://${item.link}`}>{item.link}</a>
                      </Typography>
                    ))}
                  {block.links &&
                    block.links.map((link, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textIndent: "32px",
                          textAlign: "justify",
                        }}
                      >
                        {link}
                      </Typography>
                    ))}
                </Box>
              ))}
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "14px", md: "20px" },
                  fontWeight: 400,
                  lineHeight: { xs: "22px", md: "28px" },
                  textAlign: "justify",
                }}
              >
                <b>Телефоны:</b> 22-35-61, {phone}
              </Typography>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "14px", md: "20px" },
                  fontWeight: 400,
                  lineHeight: { xs: "22px", md: "28px" },
                  textAlign: "justify",
                }}
              >
                <b>E-mail:</b>&nbsp;
                <a href="mailto:profi-klinika@mail.ru">profi-klinika@mail.ru</a>
              </Typography>
              {patientsInformationLinks.map((link, index) => (
                <Typography
                  key={index}
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: { xs: "14px", md: "20px" },
                    fontWeight: 400,
                    lineHeight: { xs: "22px", md: "28px" },
                    textAlign: "justify",
                  }}
                >
                  <a href={link.link}>{link.text}</a>
                </Typography>
              ))}
            </BoxWrapper>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default PatientsInformation;
