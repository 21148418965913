import React from "react";
// import TimeTable from "./timeTable";
import Welcome from "./welcome";
import Slider from "./slider";
import Contacts from "./contacts";
import PageWrapper from "../../common/pageWrapper";

const Home = () => {
  return (
    <PageWrapper>
      {/* <TimeTable /> */}
      <Welcome />
      <Slider />
      <Contacts />
    </PageWrapper>
  );
};

export default Home;
