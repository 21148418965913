import { Box, Typography } from "@mui/material";
import React from "react";
import NavBarButton from "./components/navBarButtotn";
import { navigation, phone, phone2 } from "../../common/constants";
import { useLocation } from "react-router-dom";
import BlockWrapper from "../common/blockWrapper";

const NavBar = () => {
  const { pathname } = useLocation();

  const getSelected = (link) =>
    (pathname === "/" && link === "/") ||
    (link !== "/" && pathname.includes(link, 0));

  return (
    <Box
      sx={{
        background: "#FFF",
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
        width: "-webkit-fill-available",
      }}
    >
      <BlockWrapper>
        <Box
          sx={{ p: "16px", display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            sx={{
              color: "#9A8891",
              fontFamily: "Manrope",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "32px",
              m: "auto 0",
            }}
          >
            Профи-клиника
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
          >
            <Typography
              sx={{
                color: "#546272",
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "27px",
                m: "auto 0",
              }}
            >
              {phone}
            </Typography>
            <Typography
              sx={{
                color: "#546272",
                fontFamily: "Manrope",
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "27px",
                m: "auto 0",
              }}
            >
              {phone2}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ height: "1px", opacity: "0.25", background: "#546272" }}
        ></Box>
        <Box
          sx={{
            p: "10px 22px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {navigation.map((item, index) => (
            <NavBarButton
              key={index}
              text={item.text}
              link={item.link}
              selected={getSelected(item.link)}
            />
          ))}
        </Box>
      </BlockWrapper>
    </Box>
  );
};

export default NavBar;
