export const patientsInformationBlocks = [
  {
    text: [
      "Вы можете получить медицинскую помощь без взимания платы в рамках программы государственных гарантий бесплатного оказания гражданам медицинской помощи и территориальной программы государственных гарантий бесплатного оказания гражданам медицинской помощи.",
      "Ближайшие медицинские учреждения, которые оказывают медицинские услуги за счет средств обязательного медицинского страхования:",
    ],
    textAndLinks: [
      {
        text: 'МБУЗ "Городская больница № 1", Ростовская область, г. Новочеркасск, ул. Мацоты С.В.,28. Телефон: 8-8635-234319, сайт:',
        link: "gbn1.ru",
      },
      {
        text: "МБУЗ ГБСМП, Ростовская область, г. Новочеркасск, ул. Красноармейская, 30. Телефон: 8-8635-225631, сайт:",
        link: "bsmp-novoch.ru",
      },
      {
        text: 'МБУЗ "Городская больница № 2", Ростовская область, г. Новочеркасск, ул. Гвардейская, 3. Телефон: 8-8635-232255, сайт:',
        link: "gb2novoch.ru",
      },
      {
        text: 'МБУЗ "Городская поликлиника", Ростовская область, г.Новочеркасск, ул. Александровская, 72. Телефон : 8-8635-242330, сайт:',
        link: "novoch-gp.ru",
      },
      {
        text: "МБУЗ «Детская городская больница», Ростовская область, г.Новочеркасск, ул.Первомайская, 99. Телефон: 8-8635-222173, сайт:",
        link: "detgorbol.ru",
      },
      {
        text: 'МБУЗ "Городская СГБ" Ростовская область, г.Новочеркасск, ул.Фрунзе, 78. Телефон: 8-8635-223024, сайт:',
        link: "gsgb.ru",
      },
    ],
  },
  {
    bold: [
      "Адреса и телефоны органа исполнительной власти субъекта Российской Федерации в сфере охраны здоровья граждан: ",
    ],
    links: [
      "Министерство здравоохранения Ростовской области, 344029, г.Ростов-на-Дону, ул.1-й Конной Армии, 33, тел (863) 242-41-09;",
      "Управление здравоохранения Администрации города Новочеркасска, 346430, г. Новочеркасск, ул.Дворцовая,5, тел.: 8(8635) 24-14-76",
    ],
  },
  {
    bold: [
      "Адреса и телефоны территориального органа Федеральной службы по надзору в сфере здравоохранения: ",
    ],
    links: [
      'Территориальный орган Росздравнадзора по Ростовской области, 344037 г. Ростов-на-Дону, ул.Ченцова 71/63 "б" тел. (863) 286-98-17',
    ],
  },
  {
    bold: [
      "Адреса и телефоны территориального органа Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека:",
    ],
    links: [
      "Управление Федеральной службы по надзору в сфере защиты прав потребителей и благополучия человека по Ростовской области, 344019, г. Ростов-на-Дону, ул. 18 линия, 17 тел. (863)251-74-17;",
      "Территориальный отдел Управления Роспотребнадзора по Ростовской области в г. Новочеркасске, Аксайском, Багаевском, Веселовском районах, 346428 Ростовская область, г. Новочеркасск, пер. Юннатов, д. 3, тел. (86352) 4-70-10",
    ],
  },
  {
    bold: [
      "Стандарты медицинской помощи и клинические рекомендации (при их наличии), с учетом и на основании которых (соответственно) оказываются медицинские услуги",
    ],
    textAndLinks: [
      {
        text: 'Официальный портал правовой информации: ',
        link: "http://www.pravo.gov.ru/",
      },
      {
        text: 'Официальный сайт Министерства здравоохранения Российской Федерации: ',
        link: "https://minzdrav.gov.ru/",
      },
    ],
  },
  {
    bold: ["Прием граждан по личным вопросам:"],
    text: [
      "Директор ООО «Профи-клиника» Г.Е. Толстогузова: вторник, 18.00-19.00",
      "Главный врач ООО «Профи-клиника» Кошлаков В.А.: четверг, 18.00-19.00",
    ],
  },
];

export const patientsInformationLinks = [
  {
    text: "ПОЛОЖЕНИЕ О ПОРЯДКЕ И УСЛОВИЯХ ПРЕДОСТАВЛЕНИЯ ПЛАТНЫХ УСЛУГ В ООО «Профи-клиника»;",
    link: "/files/poloj-o-platnih-uslugah.docx",
  },
  {
    text: "Правила внутреннего распорядка ООО «Профи-клиника»;",
    link: "/files/pravila-vnutr-rasp.docx",
  },
  {
    text: "Правила подготовки к диагностическим обследованиям;",
    link: "/rules",
  },
  {
    text: "Порядок рассмотрения обращений граждан в ООО «Профи-клиника»;",
    link: "/files/obrach_gra_dosud.docx",
  },
  {
    text: "Досудебный (внесудебный) порядок обжалования решений, действий (бездействия) должностных лиц и сотрудников ООО «Профи-клиника»;",
    link: "/files/dosud.docx",
  },
  {
    text: "О Программе государственных гарантий бесплатного оказания гражданам медицинской помощи на 2024 год и на плановый период 2025 и 2026 годов;",
    link: "http://publication.pravo.gov.ru/document/0001202312290105",
  },
  {
    text: "О Территориальной программе государственных гарантий бесплатного оказания гражданам медицинской помощи в Ростовской области на 2024 год и плановый период 2025 и 2026 годов;",
    link: "http://publication.pravo.gov.ru/document/6100202312260017",
  },
  {
    text: "ПЕРЕЧЕНЬ ЖИЗНЕННО НЕОБХОДИМЫХ И ВАЖНЕЙШИХ ЛЕКАРСТВЕННЫХ ПРЕПАРАТОВ ДЛЯ МЕДИЦИНСКОГО ПРИМЕНЕНИЯ",
    link: "/files/raps-prav2023.docx",
  },
  {
    text: "Результаты проведения специальной оценки условий труда сотрудников ООО «Профи-клиника»",
    link: "/files/perech2021.pdf",
  },
  {
    text: "Политика в отношении обработки персональных данных",
    link: "/files/polit2022.docx",
  },
];
