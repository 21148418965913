import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { phone, timeTableList } from "../../../common/constants";
import YMap from "../../common/map";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import BoxWrapper from "../../common/boxWrapper";

const Constants = () => {
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <BoxWrapper
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: "22px", md: "40px" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "24px", md: "32px" },
                        fontWeight: 600,
                        lineHeight: { xs: "32px", md: "40px" },
                      }}
                    >
                      Контакты
                    </Typography>
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "18px", md: "24px" },
                        fontWeight: 200,
                        lineHeight: { xs: "26px", md: "32px" },
                      }}
                    >
                      Профи-клиника
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#9A8891",
                            fontFamily: "Manrope",
                            fontSize: { xs: "12px", md: "16px" },
                            fontWeight: 200,
                            lineHeight: { xs: "20px", md: "24px" },
                          }}
                        >
                          Адрес
                        </Typography>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 600,
                            lineHeight: { xs: "22px", md: "26px" },
                          }}
                        >
                          г. Новочеркасск, пр. Ермака, 99
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          height: "1px",
                          width: "100%",
                          background: "#EBEBEB",
                        }}
                      ></Box>
                      {timeTableList.map((table, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#9A8891",
                              fontFamily: "Manrope",
                              fontSize: { xs: "12px", md: "16px" },
                              fontWeight: 200,
                              lineHeight: { xs: "20px", md: "24px" },
                            }}
                          >
                            {table.title}
                          </Typography>
                          {table.fields.map((field, index) => (
                            <Typography
                              key={index}
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "18px" },
                                fontWeight: 600,
                                lineHeight: { xs: "22px", md: "26px" },
                              }}
                            >
                              {field}
                            </Typography>
                          ))}
                        </Box>
                      ))}
                      <Box
                        sx={{
                          height: "1px",
                          width: "100%",
                          background: "#EBEBEB",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#9A8891",
                            fontFamily: "Manrope",
                            fontSize: { xs: "12px", md: "16px" },
                            fontWeight: 200,
                            lineHeight: { xs: "20px", md: "24px" },
                          }}
                        >
                          Телефоны
                        </Typography>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 600,
                            lineHeight: { xs: "22px", md: "26px" },
                          }}
                        >
                          8 (8635) 22-35-61
                        </Typography>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 600,
                            lineHeight: { xs: "22px", md: "26px" },
                          }}
                        >
                          {phone}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{ display: "flex", height: "100%", minHeight: "244px" }}
                  >
                    <YMap />
                  </Box>
                </Grid>
              </Grid>
            </BoxWrapper>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default Constants;
