import { Box, Typography } from "@mui/material";
import React from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import { organizationInfo } from "../../../common/constants";
import BoxWrapper from "../../common/boxWrapper";

const OrganizationInfo = () => {
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Сведения об организации
              </Typography>
            </Box>
            <BoxWrapper
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              {organizationInfo.map((item) => (
                <Typography
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: 500,
                    lineHeight: { xs: "24px", md: "26px" },
                    textAlign: "justify",
                  }}
                >
                  {item}
                </Typography>
              ))}
            </BoxWrapper>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default OrganizationInfo;
