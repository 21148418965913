import React, { useEffect, useState } from "react";
import PageWrapper from "../../common/pageWrapper";
import { services, servicesText } from "../../../common/constants";
import { Box, Grid, Typography } from "@mui/material";
import BlockWrapper from "../../common/blockWrapper";
import OpenBlock from "../../common/openBlock";
import BoxWrapper from "../../common/boxWrapper";
import SearchField from "../../common/searchField";

const Services = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    if (search) {
      let allServices = [];
      services.map((service) => {
        service.services.map((service) => {
          if (
            service.name.toLowerCase().includes(search.toLowerCase()) ||
            (service.code.includes(search) && search !== "")
          ) {
            allServices = [...allServices, service];
          }
        });
      });
      setSearchResults(allServices);
    } else {
      setSearchResults([]);
    }
  }, [search]);
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" },
                gap: "24px",
              }}
            >
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Услуги и цены
              </Typography>
              <Box sx={{ maxWidth: { xs: "none", md: "200px" } }} >
                <SearchField handleSearch={(val) => setSearch(val)} fullWidth />
              </Box>
            </Box>
            {search ? (
              searchResults.length === 0 ? (
                <BoxWrapper>
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "18px", md: "22px" },
                      fontWeight: 600,
                      lineHeight: { xs: "26px", md: "30px" },
                    }}
                  >
                    Услуги не найдены
                  </Typography>
                </BoxWrapper>
              ) : (
                <OpenBlock blockName={"Найденные услуги"} open={true}>
                  <Grid container>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 500,
                            lineHeight: { xs: "22px", md: "26px" },
                            p: { xs: "6px", md: "12px" },
                            m: "auto 0",
                            overflowWrap: "anywhere",
                            textAlign: "center",
                          }}
                        >
                          Код
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 500,
                            lineHeight: { xs: "22px", md: "26px" },
                            p: { xs: "6px", md: "12px" },
                            m: "auto 0",
                            overflowWrap: "anywhere",
                          }}
                        >
                          Наименование видов медицинских услуг
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "18px" },
                            fontWeight: 500,
                            lineHeight: { xs: "22px", md: "26px" },
                            p: { xs: "6px", md: "12px" },
                            m: "auto 0",
                            overflowWrap: "anywhere",
                          }}
                        >
                          Цена, руб
                        </Typography>
                      </Grid>
                    </Grid>
                    {searchResults.map((item, number) => (
                      <Grid
                        key={number}
                        item
                        container
                        xs={12}
                        sx={{
                          borderTop: "1px solid #EBEBEB",
                          ":hover": {
                            background: "rgba(84, 98, 114, 0.1)",
                          },
                        }}
                      >
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                              textAlign: "center",
                            }}
                          >
                            {item.code}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {item.price}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </OpenBlock>
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "24px", md: "42px" },
                }}
              >
                <BoxWrapper>
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "14px", md: "18px" },
                      fontWeight: 500,
                      lineHeight: { xs: "22px", md: "26px" },
                      textAlign: "justify",
                    }}
                  >
                    {servicesText}
                  </Typography>
                </BoxWrapper>
                {services.map((service, index) => (
                  <OpenBlock
                    key={index}
                    blockName={service.title}
                    open={false}
                    sx={{ background: index % 2 ? "#fff" : "#E7E1E6" }}
                  >
                    <Grid container>
                      <Grid item container xs={12}>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                              textAlign: "center",
                            }}
                          >
                            Код
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                            }}
                          >
                            Наименование видов медицинских услуг
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "18px" },
                              fontWeight: 500,
                              lineHeight: { xs: "22px", md: "26px" },
                              p: { xs: "6px", md: "12px" },
                              m: "auto 0",
                              overflowWrap: "anywhere",
                            }}
                          >
                            Цена, руб
                          </Typography>
                        </Grid>
                      </Grid>
                      {service.services.map((item, number) => (
                        <Grid
                          key={number}
                          item
                          container
                          xs={12}
                          sx={{
                            borderTop:
                              index % 2
                                ? "1px solid #EBEBEB"
                                : "1px solid #B8B4B3",
                            ":hover": {
                              background: "rgba(84, 98, 114, 0.1)",
                            },
                          }}
                        >
                          <Grid
                            item
                            xs={2}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "18px" },
                                fontWeight: 500,
                                lineHeight: { xs: "22px", md: "26px" },
                                p: { xs: "6px", md: "12px" },
                                m: "auto 0",
                                overflowWrap: "anywhere",
                                textAlign: "center",
                              }}
                            >
                              {item.code}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "18px" },
                                fontWeight: 500,
                                lineHeight: { xs: "22px", md: "26px" },
                                p: { xs: "6px", md: "12px" },
                                m: "auto 0",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "14px", md: "18px" },
                                fontWeight: 500,
                                lineHeight: { xs: "22px", md: "26px" },
                                p: { xs: "6px", md: "12px" },
                                m: "auto 0",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {item.price}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </OpenBlock>
                ))}
              </Box>
            )}
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default Services;
