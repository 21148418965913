import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { documents } from "../../../common/constants";
import ImageDialog from "../../dialogs/imageDialog";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import BoxWrapper from "../../common/boxWrapper";

const License = () => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(documents[0]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSetImage = (document) => {
    setImage(document);
    setOpen(true);
  };
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Правовые документы
              </Typography>
            </Box>
            <BoxWrapper
              sx={{
                background: "#E7E1E6",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
                minHeight: "300px",
              }}
            >
              <Grid container spacing={4}>
                {documents.map((document, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <Box sx={{ width: "100%" }}>
                      <CardMedia
                        component="img"
                        image={document}
                        onClick={() => handleSetImage(document)}
                        alt="License"
                        sx={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          height: "auto",
                          width: "auto",
                          m: "auto",
                          cursor: "pointer",
                          border: "1px solid transparent",
                          ":hover": {
                            border: "1px solid rgba(125, 199, 241, 0.50)",
                            boxShadow:
                              "0px 0px 16px 0px rgba(125, 199, 241, 0.50)",
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </BoxWrapper>
          </Box>
        </BlockWrapper>
      </Box>
      <ImageDialog open={open} handleClose={handleClose} image={image} />
    </PageWrapper>
  );
};

export default License;
