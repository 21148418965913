import React from "react";
import { Box, Typography } from "@mui/material";

const ProfileOpenBlockField = ({ value }) => {
  return (
    <Box sx={{ display: "flex", gap: { xs: "16px", md: "32px" } }}>
      <Box
        sx={{
          width: "8px",
          maxWidth: "8px",
          minWidth: "8px",
          height: "auto",
          position: "relative",
          display: "flex",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            margin: "0 auto",
            left: 0,
            right: 0,
            top: 0,
            textAlign: "center",
            width: "2px",
            height: "-webkit-fill-available",
            background: "#9A888A",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            margin: "auto",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            textAlign: "center",
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            background: "#9A888A",
          }}
        ></Box>
      </Box>
      <Box sx={{ p: "8px 0", display: "flex" }}>
        <Typography
          sx={{
            color: "#546272",
            fontFamily: "Manrope",
            fontSize: { xs: "12px", md: "16px" },
            fontWeight: 400,
            lineHeight: { xs: "20px", md: "24px" },
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileOpenBlockField;
