import { Box, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { reviews } from "../../../common/constants";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ImageDialog from "../../dialogs/imageDialog";
import BlockWrapper from "../../common/blockWrapper";

const Slider = () => {
  const [open, setOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(1);
  const [image, setImage] = useState(reviews[1]);
  const nextImage = () => {
    if (reviews.length > activeImage + 1) {
      setActiveImage(activeImage + 1);
    }
  };
  const prevImage = () => {
    if (activeImage > 0) {
      setActiveImage(activeImage - 1);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSetImage = (index) => {
    setImage(reviews[index]);
    setOpen(true);
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        background:
          "radial-gradient(circle, rgba(84,98,114,0.5) 5%, rgba(235,235,235,1) 48%)",
      }}
    >
      <BlockWrapper>
        <Box sx={{ p: "56px 0" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Отзывы наших пациентов
              </Typography>
            </Box>
            <Box
              sx={{
                height: "400px",
                display: "flex",
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  m: "auto 10%",
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "space-between",
                  height: "250px",
                  width: "100%",
                  p: { xs: "0 16px", lg: "0" },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {reviews[activeImage - 1] && (
                    <CardMedia
                      component="img"
                      image={reviews[activeImage - 1]}
                      alt="Review"
                      sx={{
                        maxHeight: "250px",
                        maxWidth: "200px",
                        height: "auto",
                        width: "auto",
                        m: "auto",
                        opacity: "0.75",
                      }}
                    />
                  )}
                </Box>
                <Box sx={{ display: "flex" }}>
                  {reviews[activeImage + 1] && (
                    <CardMedia
                      component="img"
                      image={reviews[activeImage + 1]}
                      alt="Review"
                      sx={{
                        maxHeight: "250px",
                        maxWidth: "200px",
                        height: "auto",
                        width: "auto",
                        m: "auto",
                        opacity: "0.75",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  marginLeft: "auto",
                  marginRight: "auto",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
              >
                <CardMedia
                  component="img"
                  image={reviews[activeImage]}
                  onClick={() => handleSetImage(activeImage)}
                  alt="Review"
                  sx={{
                    maxHeight: "400px",
                    maxWidth: "400px",
                    height: "auto",
                    width: "auto",
                    m: "auto",
                    cursor: "pointer",
                    border: "1px solid transparent",
                    ":hover": {
                      border: "1px solid rgba(125, 199, 241, 0.50)",
                      boxShadow: "0px 0px 16px 0px rgba(125, 199, 241, 0.50)",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: { xs: "4px", sm: "8px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  p: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  ":hover": { background: "rgba(84,98,114,0.1)" },
                }}
                onClick={prevImage}
              >
                <KeyboardArrowLeftIcon
                  sx={{ fontSize: "28px", color: "#546272" }}
                />
              </Box>
              {reviews.map((review, index) => (
                <Box
                  key={index}
                  sx={{
                    m: "auto 0",
                    display: "flex",
                    p: activeImage === index ? "0" : "4px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    ":hover": { background: "rgba(84,98,114,0.25)" },
                  }}
                  onClick={() => setActiveImage(index)}
                >
                  <Box
                    sx={{
                      m: "auto",
                      background: "#546272",
                      borderRadius: "50%",
                      height: activeImage === index ? "10px" : "6px",
                      width: activeImage === index ? "10px" : "6px",
                    }}
                  ></Box>
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  p: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  ":hover": { background: "rgba(84,98,114,0.1)" },
                }}
                onClick={nextImage}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    fontSize: "28px",
                    transform: "rotate(180deg)",
                    color: "#546272",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </BlockWrapper>
      <ImageDialog open={open} handleClose={handleClose} image={image} />
    </Box>
  );
};

export default Slider;
