import React from "react";
import { Box } from "@mui/material";

const PageWrapper = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{ m: { xs: "36px 0 420px", md: "36px 0 336px" }, ...props.sx }}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
