import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const NavBarButton = ({ text, link, selected }) => {
  return (
    <Box
      component={Link}
      to={link}
      sx={{
        border: selected
          ? "1px solid rgba(154, 136, 138, 0.5)"
          : "1px solid #FFF",
        background: selected ? "rgba(154, 136, 138, 0.15)" : "#FFF",
        p: "4px 8px",
        m: "4px 24px",
        cursor: "pointer",
        borderRadius: "8px",
        textDecoration: "none",
        ":hover": {
          border: "1px solid rgba(84, 98, 114, 0.5)",
          background: "rgba(84, 98, 114, 0.15)",
        },
      }}
    >
      <Typography
        sx={{
          color: "#546272",
          textAlign: "center",
          fontFamily: "Manrope",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default NavBarButton;
