import React from "react";
import { Button, Typography } from "@mui/material";

const CommonDataButton = ({
  startIcon,
  endIcon,
  text,
  onClick,
  sx,
  textSx,
  buttonControl,
}) => {
  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        padding: "12px 16px",
        borderRadius: "24px",
        border: "1px solid #EBEBEB",
        background: "#FFF",
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        textTransform: "none",
        height: "fit-content",
        position:"relative",
        "&:hover": {
          border: "1px solid rgba(84, 98, 114, 0.5)",
          background: "rgba(84, 98, 114, 0.2)",
        },
        alignItems: "center",
        ...sx,
      }}
      onClick={() => onClick()}
      {...buttonControl}
    >
      {startIcon}
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          fontFamily: "Manrope",
          color: "#546272",
          ...textSx,
        }}
      >
        {text}
      </Typography>
      {endIcon}
    </Button>
  );
};

export default CommonDataButton;
