import { Box, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { homeAnalyzes, homeSpecialists } from "../../../common/constants";
import Vector from "../../../assets/images/Vector.svg";
import Star from "../../../assets/images/Star.svg";
import Star2 from "../../../assets/images/Star2.svg";
import Union from "../../../assets/images/Union.svg";
import CommonDataButton from "../../common/commonDataButton";
import { useNavigate } from "react-router-dom";
import BlockWrapper from "../../common/blockWrapper";
import BoxWrapper from "../../common/boxWrapper";

const Welcome = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <BlockWrapper>
        <Box
          sx={{
            p: { xs: "0 16px 32px", md: "0 16px 56px", lg: "0 0 56px" },
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", md: "42px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                color: "#546272",
                fontFamily: "Manrope",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: 600,
                lineHeight: { xs: "36px", md: "48px" },
              }}
            >
              Добро пожаловать!
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12}>
                <BoxWrapper
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "8px", md: "12px" },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Star}
                    alt="Star"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      width: "auto",
                      height: "auto",
                      flexShrink: 0,
                      opacity: 0.25,
                      borderRadius: { xs: "0 16px 0 0", md: "0 24px 0 0" },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: 500,
                      lineHeight: { xs: "24px", md: "26px" },
                      textAlign: "justify",
                    }}
                  >
                    В нашем медицинском центре Вы всегда можете рассчитывать на
                    помощь опытных и квалифицированных специалистов.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#546272",
                      fontFamily: "Manrope",
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: 500,
                      lineHeight: { xs: "24px", md: "26px" },
                      textAlign: "justify",
                    }}
                  >
                    Забор анализов ведется специалистами лаборатории КДЛ.
                  </Typography>
                </BoxWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <BoxWrapper
                  sx={{
                    background: "#E7E1E6",
                    position: "relative",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={Vector}
                    alt="Vector"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "auto",
                      height: "auto",
                      flexShrink: 0,
                      opacity: 0.2,
                      borderRadius: { xs: "0 0 16px 0", md: "0 0 24px 0" },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: { xs: "14px", md: "28px" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "18px", md: "22px" },
                        fontWeight: 600,
                        lineHeight: { xs: "24px", md: "30px" },
                      }}
                    >
                      Прием ведут:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { xs: "8px", md: "12px" },
                      }}
                    >
                      {homeSpecialists.map((specialist, index) => (
                        <Typography
                          key={index}
                          sx={{
                            color: "#546272",
                            fontFamily: "Manrope",
                            fontSize: { xs: "14px", md: "16px" },
                            fontWeight: 400,
                            lineHeight: { xs: "22px", md: "24px" },
                            textAlign: "justify",
                          }}
                        >
                          <b>{specialist.title}</b>
                          {specialist.name}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                </BoxWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "16px", md: "32px" },
                  }}
                >
                  <BoxWrapper
                    sx={{
                      position: "relative",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={Union}
                      alt="Union"
                      sx={{
                        position: "absolute",
                        bottom: "5%",
                        right: "5%",
                        width: "auto",
                        height: "auto",
                        flexShrink: 0,
                        opacity: 0.2,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { xs: "14px", md: "28px" },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "18px", md: "22px" },
                          fontWeight: 600,
                          lineHeight: { xs: "24px", md: "30px" },
                          textAlign: "justify",
                        }}
                      >
                        Мы принимаем более 1500 видов анализов, в том числе:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: { xs: "8px", md: "12px" },
                        }}
                      >
                        {homeAnalyzes.map((analysis, index) => (
                          <Typography
                            key={index}
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "14px", md: "16px" },
                              fontWeight: 400,
                              lineHeight: { xs: "22px", md: "24px" },
                              textAlign: "justify",
                            }}
                          >
                            {analysis}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </BoxWrapper>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CommonDataButton
                      startIcon={
                        <CardMedia
                          component="img"
                          image={Star2}
                          alt="Star2"
                          sx={{
                            position: "absolute",
                            top: "2px",
                            left: "5%",
                            width: "56px",
                            height: "56px",
                            flexShrink: 0,
                            opacity: 0.2,
                          }}
                        />
                      }
                      text="Все услуги"
                      onClick={() => navigate("/services")}
                      sx={{ width: "100%", p: "14px 20px" }}
                      textSx={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: 600,
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CommonDataButton
                      endIcon={
                        <CardMedia
                          component="img"
                          image={Star2}
                          alt="Star2"
                          sx={{
                            position: "absolute",
                            bottom: "2px",
                            right: "5%",
                            width: "56px",
                            height: "56px",
                            flexShrink: 0,
                            opacity: 0.2,
                            transform: "rotate(180deg)",
                          }}
                        />
                      }
                      text="Все специалисты"
                      onClick={() => navigate("/specialists")}
                      sx={{
                        background: "#E7E1E6",
                        width: "100%",
                        p: "14px 20px",
                      }}
                      textSx={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: 600,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BlockWrapper>
    </Box>
  );
};

export default Welcome;
