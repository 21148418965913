export const timeTableList = [
  {
    title: "Режим работы:",
    fields: ["пн-пт: 8.00 - 19.00", "сб: 8.00 - 14.00", "вс: выходной"],
  },
  {
    title: "Забор анализов:",
    fields: ["пн-сб: 8.00 - 11.00"],
  },
  {
    title: "Выдача результатов:",
    fields: ["пн-пт: 11.00 - 18.30", "сб: 11.00 - 14.00"],
  },
];

export const homeSpecialists = [
  {
    title: "Главный врач клиники, уролог, врач узи-диагностики",
    name: " - отличник здравоохранения Российской Федерации, Кошлаков В.А.;",
  },
  {
    title: "Эндокринолог",
    name: " – врач высшей категории Лазарева Т. С.;",
  },
  {
    title: "Кардиолог",
    name: " – Понятовский И. М.(г. Ростов-на-Дону);",
  },
  {
    title: "Уролог, врач ультразвуковой диагностики",
    name: " – Григорьев Д. В.;",
  },
  {
    title: "Терапевт, врач узи-диагностики",
    name: " – Калмыкова Л. Ф.;",
  },
  // {
  //   title: "Акушер-гинеколог",
  //   name: " – Кисленко В.А.;",
  // },
  {
    title: "Акушер-гинеколог, врач ультразвуковой диагностики",
    name: " – врач высшей категории по специальности «Акушерство и гинекология» Письменскова О.А.;",
  },
  {
    title: "Аллерголог-иммунолог",
    name: " – Грушкина О. В.;",
  },
  {
    title: "Кардиолог",
    name: " – Егоров В. А.;",
  },
  {
    title: "Педиатр, врач ультразвуковой диагностики",
    name: " – Лоцмер Н. В.;",
  },
  {
    title: "Невролог",
    name: " – Лемешко М. В.;",
  },
  {
    title: "Оториноларинголог",
    name: " – Кружалина А. А.;",
  },
  {
    title: "Гастроэнтеролог",
    name: " – кандидат медицинских наук Хаммудех Н.Т.И.;",
  },
  {
    title: "Дерматовенеролог",
    name: " – Скоба А. А.;",
  },
  {
    title: "Специалисты со средним медицинским образованием",
    name: " – медицинские сестры Карпова С.В., Полякова М.С.",
  },
];

export const homeAnalyzes = [
  "ПЦР-исследования;",
  "серология;",
  "исследования на гормоны;",
  "биохимия;",
  "онкомаркеры;",
  "аутоиммунологические исследования;",
  "аллергодиагностика;",
  "бактериологические исследования;",
  "общеклинические исследования.",
];
