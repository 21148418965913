export const servicesText =
  "Обращаем ваше внимание на то, что данный интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 (2) Гражданского кодекса Российской Федерации. Для получения подробной информации о наличии и стоимости указанных услуг, пожалуйста, обращайтесь в регистратуру клиники по телефонам: 8-961-324-78-66, 22-35-61";

export const services = [
  {
    title: "Прием врачей-специалистов",
    services: [
      {
        code: "B01.047.001",
        name: "Прием (осмотр, консультация) врача-терапевта первичный",
        price: 1100,
      },
      {
        code: "B01.047.002",
        name: "Прием (осмотр, консультация) врача-терапевта повторный",
        price: 1000,
      },
      {
        code: "B01.023.001",
        name: "Прием (осмотр, консультация) врача-невролога первичный",
        price: 1100,
      },
      {
        code: "B01.023.002",
        name: "Прием (осмотр, консультация) врача-невролога повторный",
        price: 1000,
      },
      {
        code: "B01.058.001",
        name: "Прием (осмотр, консультация) врача-эндокринолога первичный",
        price: 1100,
      },
      {
        code: "B01.058.002",
        name: "Прием (осмотр, консультация) врача-эндокринолога повторный",
        price: 1000,
      },
      {
        code: "B01.001.001",
        name: "Прием (осмотр, консультация) врача-акушера-гинеколога первичный",
        price: 1300,
      },
      {
        code: "B01.001.001",
        name: "Прием (осмотр, консультация) врача-акушера-гинеколога первичный (врач высшей категории Письменскова О.А.)",
        price: 1500,
      },
      {
        code: "B01.001.002",
        name: "Прием (осмотр, консультация) врача-акушера-гинеколога повторный",
        price: 1000,
      },
      {
        code: "B01.053.001",
        name: "Прием (осмотр, консультация) врача-уролога первичный",
        price: 1200,
      },
      {
        code: "B01.053.002",
        name: "Прием (осмотр, консультация) врача-уролога повторный",
        price: 1000,
      },
      {
        code: "B01.002.001",
        name: "Прием (осмотр, консультация) врача-аллерголога-иммунолога первичный",
        price: 1300,
      },
      {
        code: "B01.002.002",
        name: "Прием (осмотр, консультация) врача-аллерголога-иммунолога повторный",
        price: 1000,
      },
      {
        code: "B01.028.001",
        name: "Прием (осмотр, консультация) врача-оториноларинголога первичный",
        price: 1200,
      },
      {
        code: "B01.028.002",
        name: "Прием (осмотр, консультация) врача-оториноларинголога повторный",
        price: 1000,
      },
      {
        code: "B01.015.001",
        name: "Прием (осмотр, консультация) врача-кардиолога первичный",
        price: 1500,
      },
      {
        code: "B01.015.002",
        name: "Прием (осмотр, консультация) врача-кардиолога повторный",
        price: 1200,
      },
      {
        code: "B01.031.001",
        name: "Прием (осмотр, консультация) врача-педиатра первичный",
        price: 1100,
      },
      {
        code: "B01.031.002",
        name: "Прием (осмотр, консультация) врача-педиатра повторный",
        price: 1000,
      },
      {
        code: "B01.008.001",
        name: "Прием (осмотр, консультация) врача-дерматовенеролога первичный",
        price: 1100,
      },
      {
        code: "B01.008.002",
        name: "Прием (осмотр, консультация) врача-дерматовенеролога повторный",
        price: 1000,
      },
    ],
  },
  {
    title: "Манипуляции, исследования, процедуры",
    services: [
      {
        code: "A11.12.009",
        name: "Взятие крови из периферической вены",
        price: 150,
      },
      {
        code: "A11.12.003",
        name: "Внутривенное введение лекарственных препаратов (1 инъекция)",
        price: 180,
      },
      {
        code: "A11.02.002",
        name: "Внутримышечное введение лекарственных препаратов (1 инъекция)",
        price: 120,
      },
      {
        code: "A11.01.002",
        name: "Подкожное введение лекарственных препаратов (1 инъекция)",
        price: 120,
      },
      {
        code: "A11.12.003.001",
        name: "Непрерывное внутривенное введение лекарственных препаратов (1 лекарственный препарат)",
        price: 600,
      },
      {
        code: "A11.12.003.001",
        name: "Непрерывное внутривенное введение лекарственных препаратов (за каждый последующий препарат)",
        price: 200,
      },
      {
        code: "A05.10.006",
        name: "Регистрация электрокардиограммы",
        price: 600,
      },
      {
        code: "A05.10.006",
        name: "Регистрация электрокардиограммы (в рамках приема врача-специалиста)",
        price: 500,
      },
      {
        code: "A12.10.001",
        name: "Электрокардиография с физической нагрузкой",
        price: 900,
      },
      {
        code: "A05.10.008",
        name: "Холтеровское мониторирование сердечного ритма",
        price: 2000,
      },
      {
        code: "A12.28.006",
        name: "Измерение скорости потока мочи (урофлоуметрия)",
        price: 800,
      },
      {
        code: "A12.09.001",
        name: "Исследование неспровоцированных дыхательных объемов и потоков  (спирометрия,  с автоматической интерпретацией результата)",
        price: 600,
      },
      {
        code: "A12.25.001",
        name: "Тональная аудиометрия",
        price: 1000,
      },
      {
        code: "A11.28.006",
        name: "Получение уретрального отделяемого",
        price: 250,
      },
      {
        code: "A11.08.010.002",
        name: "Получение мазков со слизистой оболочки ротоглотки",
        price: 150,
      },
      {
        code: "A11.08.010.001",
        name: "Получение мазков со слизистой оболочки носоглотки",
        price: 150,
      },
      {
        code: "A11.20.002",
        name: "Получение цервикального мазка",
        price: 350,
      },
      {
        code: "A11.20.005",
        name: "Получение влагалищного мазка",
        price: 350,
      },
      {
        code: "A11.20.024",
        name: "Введение лекарственных препаратов интравагинально",
        price: 450,
      },
      {
        code: "A03.20.001",
        name: "Кольпоскопия",
        price: 1800,
      },
      {
        code: "A11.20.014",
        name: "Введение внутриматочной спирали",
        price: 1400,
      },
      {
        code: "A11.20.015",
        name: "Удаление внутриматочной спирали",
        price: 1400,
      },
      {
        code: "A11.20.003",
        name: "Биопсия тканей матки (аспирационная биопсия эндометрия с применением пайпель-зонда)",
        price: 700,
      },
      {
        code: "A16.08.006.001",
        name: "Передняя тампонада носа",
        price: 600,
      },
      {
        code: "A16.08.016",
        name: "Промывание лакун миндалин",
        price: 550,
      },
      {
        code: "A11.08.023",
        name: "Инстилляция лекарственных препаратов при заболеваниях верхних дыхательных путей",
        price: 550,
      },
      {
        code: "A16.08.007",
        name: "Удаление инородного тела глотки или гортани",
        price: 600,
      },
      {
        code: "A16.08.011",
        name: "Удаление инородного тела носа",
        price: 600,
      },
      {
        code: "A16.25.008",
        name: "Удаление инородного тела из слухового отверстия",
        price: 600,
      },
      {
        code: "A16.25.007",
        name: "Удаление ушной серы (1 ухо)",
        price: 500,
      },
      {
        code: "A16.25.007",
        name: "Удаление ушной серы (2 уха)",
        price: 700,
      },
      {
        code: "A16.25.012",
        name: "Продувание слуховой трубы (по Политцеру)",
        price: 400,
      },
      {
        code: "A16.01.011",
        name: "Вскрытие фурункула наружного слухового прохода",
        price: 1000,
      },
      {
        code: "A03.25.001",
        name: "Вестибулометрия",
        price: 1000,
      },
      {
        code: "A21.25.002",
        name: "Массаж барабанных перепонок",
        price: 400,
      },
      {
        code: "A11.28.007",
        name: "Катетеризация мочевого пузыря (первичная установка катетера)",
        price: 600,
      },
      {
        code: "A11.28.007",
        name: "Катетеризация мочевого пузыря (замена катетера)",
        price: 650,
      },
      {
        code: "A11.28.007",
        name: "Катетеризация мочевого пузыря (снятие катетера)",
        price: 200,
      },
    ],
  },
  {
    title: "Ультразвуковая диагностика",
    services: [
      {
        code: "A04.30.010",
        name: "УЗИ органов малого таза комплексное (трансвагинальное и трансабдоминальное)",
        price: 1400,
      },
      {
        code: "A04.20.001.001",
        name: "УЗИ матки и придатков трансвагинальное",
        price: 1100,
      },
      {
        code: "A04.20.001",
        name: "УЗИ матки и придатков трансабдоминальное",
        price: 1000,
      },
      {
        code: "A04.20.002",
        name: "УЗИ молочных желез",
        price: 1300,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (1  коленный  сустав)",
        price: 850,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (2  коленных   сустава)",
        price: 1600,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (1  тазобедренный  сустав)",
        price: 750,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (2  тазобедренный  сустава)",
        price: 1400,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (1  локтевой  сустав)",
        price: 600,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (2 локтевых   сустава)",
        price: 1100,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (1  плечевой сустав)",
        price: 800,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава ( 2-х плечевых  сустава)",
        price: 1500,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (мелкие суставы кисти 1  руки с лучезапястным суставом)",
        price: 600,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (мелкие суставы кистей  2-х рук с лучезапястными суставами)",
        price: 1100,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (1 голеностопный сустав и мелкие суставы стопы)",
        price: 750,
      },
      {
        code: "A04.04.001",
        name: "УЗИ сустава (2  голеностопных сустава и мелкие   суставы стоп)",
        price: 1400,
      },
      {
        code: "A04.28.003",
        name: "УЗИ органов мошонки (с оценкой тестикулярной гемодинамики)",
        price: 1000,
      },
      {
        code: "A04.28.002.001",
        name: "УЗИ почек",
        price: 750,
      },
      {
        code: "A04.22.002",
        name: "УЗИ надпочечников",
        price: 650,
      },
      {
        code: "A04.22.001",
        name: "УЗИ щитовидной железы и паращитовидных желез",
        price: 800,
      },
      {
        code: "A04.21.001",
        name: "УЗИ предстательной железы",
        price: 700,
      },
      {
        code: "A04.14.001",
        name: "УЗИ печени",
        price: 600,
      },
      {
        code: "A04.06.001",
        name: "УЗИ селезенки",
        price: 600,
      },
      {
        code: "A04.15.001",
        name: "УЗИ поджелудочной железы",
        price: 600,
      },
      {
        code: "A04.14.002",
        name: "УЗИ желчного пузыря и протоков",
        price: 600,
      },
      {
        code: "A04.14.002.001",
        name: "УЗИ желчного пузыря с определением его сократимости",
        price: 700,
      },
      {
        code: "A04.01.001",
        name: "УЗИ мягких тканей (одна анатомическая зона)",
        price: 600,
      },
      {
        code: "A04.06.002",
        name: "УЗИ лимфатических узлов (одна анатомическая зона)",
        price: 600,
      },
      {
        code: "A04.28.002.003",
        name: "УЗИ мочевого пузыря",
        price: 600,
      },
      {
        code: "A04.28.002.005",
        name: "УЗИ мочевого пузыря с определением остаточной мочи",
        price: 700,
      },
      {
        code: "A04.21.001.001",
        name: "УЗИ предстательной железы трансректальное",
        price: 1300,
      },
      {
        code: "A04.28.002.002",
        name: "УЗИ мочеточников",
        price: 500,
      },
      {
        code: "A04.09.001",
        name: "УЗИ плевральной полости",
        price: 600,
      },
      {
        code: "A04.28.001",
        name: "УЗИ почек и надпочечников",
        price: 1200,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек и мочевого пузыря)",
        price: 1300,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек и мочевого пузыря с определением остаточной мочи)",
        price: 1400,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (предстательной железы и мочевого пузыря)",
        price: 1200,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (предстательной железы и мочевого пузыря с определением остаточной мочи)",
        price: 1300,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, мочеточников и мочевого пузыря)",
        price: 1400,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, предстательной железы и мочевого пузыря)",
        price: 1400,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, мочеточников, мочевого пузыря с определением остаточной мочи)",
        price: 1500,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, предстательной железы, мочевого пузыря с определением остаточной мочи)",
        price: 1500,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, мочеточников, предстательной железы, мочевого пузыря)",
        price: 1700,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, мочеточников, предстательной железы,мочевого пузыря с определением остаточной мочи)",
        price: 1800,
      },
      {
        code: "A04.28.002",
        name: "УЗИ мочевыводящих путей (почек, надпочечников, мочеточников, мочевого пузыря)",
        price: 1700,
      },
      {
        code: "A04.16.001",
        name: "УЗИ органов брюшной полости (комплексное) (печень, желчный пузырь, поджелудочная железа)",
        price: 1400,
      },
      {
        code: "A04.16.001",
        name: "УЗИ органов брюшной полости (комплексное) (печень, желчный пузырь, селезенка, поджелудочная железа)",
        price: 1700,
      },
      {
        code: "A04.16.001",
        name: "УЗИ органов брюшной полости (комплексное) (печень, желчный пузырь, селезенка, поджелудочная железа, почки)",
        price: 1800,
      },
      {
        code: "A04.16.001",
        name: "УЗИ органов брюшной полости (комплексное) (печень, желчный пузырь,  поджелудочная железа, почки, мочевой пузырь)",
        price: 1800,
      },
      {
        code: "A04.16.001",
        name: "УЗИ органов брюшной полости (комплексное) (печень, желчный пузырь, селезенка, поджелудочная железа, почки, мочевой пузырь)",
        price: 1900,
      },
      {
        code: "A04.12.005.005",
        name: "Дуплексное сканирование экстракраниальных отделов брахиоцефальных артерий",
        price: 1600,
      },
      {
        code: "A04.12.015",
        name: "Триплексное сканирование вен (вены нижних конечностей)",
        price: 1600,
      },
      {
        code: "A04.12.006.001",
        name: "Дуплексное сканирование артерий нижних конечностей",
        price: 1600,
      },
      {
        code: "A04.12.006",
        name: "Дуплексное сканирование сосудов (артерий и вен) нижних конечностей",
        price: 2900,
      },
      {
        code: "A04.10.002",
        name: "Эхокардиография (УЗИ сердца)",
        price: 1600,
      },
      // {
      //   code: "",
      //   name: "Ультразвуковое триплексное сканирование сосудов (артерий и вен) вен нижних конечностей",
      //   price: 2800,
      // },
      // {
      //   code: "",
      //   name: "УЗИ сердца (ЭхоКГ с доплерографией)",
      //   price: 1600,
      // },
      // {
      //   code: "",
      //   name: "УЗИ лимфатических узлов 1 зона",
      //   price: 600,
      // },
      // {
      //   code: "",
      //   name: "УЗИ мягких тканей на наличие паховой грыжи",
      //   price: 1000,
      // },
    ],
  },
  // {
  //   title: "Оториноларингология",
  //   services: [
  //     {
  //       code: "",
  //       name: "Остановка носовых кровотечений (передняя тампонада)",
  //       price: 500,
  //     },
  //     {
  //       code: "",
  //       name: "Промывание лакун миндалин",
  //       price: 500,
  //     },
  //     {
  //       code: "",
  //       name: "Промывание лакун миндалин",
  //       price: 500,
  //     },
  //     {
  //       code: "",
  //       name: "Перемещение лекарственных препаратов «кукушка»",
  //       price: 400,
  //     },
  //     {
  //       code: "",
  //       name: "Удаление инородных тел из глотки, носа, наружного слухового прохода",
  //       price: 550,
  //     },
  //     {
  //       code: "",
  //       name: "Продувание слуховых труб по Политцеру",
  //       price: 400,
  //     },
  //     {
  //       code: "",
  //       name: "Вскрытие фурункулов наружного слухового прохода",
  //       price: 1000,
  //     },
  //     {
  //       code: "",
  //       name: "Исследование вестибулярного анализатора",
  //       price: 500,
  //     },
  //     {
  //       code: "",
  //       name: "Заполнение вестибулярного паспорта",
  //       price: 500,
  //     },
  //     {
  //       code: "",
  //       name: "Массаж барабанной перепонки",
  //       price: 400,
  //     },
  //   ],
  // },
];
