import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { usefulLinks } from "../../common/constants";
import BlockWrapper from "../common/blockWrapper";

const Footer = () => {
  return (
    <Box
      sx={{
        background: "#546272",
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        display: "flex",
        justifyContent: "center",
        width: "-webkit-fill-available",
        position: "absolute",
        bottom: "0",
      }}
    >
      <BlockWrapper>
        <Box
          sx={{
            p: { xs: "32px", md: "56px 32px", lg: "56px 0" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ textAlign: { xs: "center", md: "start" } }}
          >
            <Grid item xs={12} md={4} lg={3}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
              >
                <Typography
                  sx={{
                    color: "#9A8891",
                    fontFamily: "Manrope",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "32px",
                  }}
                >
                  Профи-клиника
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontFamily: "Manrope",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "200",
                    }}
                  >
                    © 2024 «Профи-клиника»
                  </Typography>
                  <Typography
                    component={Link}
                    to={"/license"}
                    sx={{
                      color: "#FFF",
                      fontFamily: "Manrope",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "200",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Правовые документы
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} lg={6}></Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "24px" }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontFamily: "Manrope",
                    fontSize: "22px",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  Полезные ссылки:
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                >
                  {usefulLinks.map((link, index) => (
                    <Typography
                      key={index}
                      component={Link}
                      to={link.link}
                      sx={{
                        color: "#FFF",
                        fontFamily: "Manrope",
                        fontSize: "14px",
                        lineHeight: "20px",
                        fontWeight: "200",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {link.name}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </BlockWrapper>
    </Box>
  );
};

export default Footer;
