import { Box, Typography } from "@mui/material";
import React from "react";
import PageWrapper from "../../common/pageWrapper";
import BlockWrapper from "../../common/blockWrapper";
import { rulesBlocks } from "../../../common/constants";
import BoxWrapper from "../../common/boxWrapper";

const Rules = () => {
  return (
    <PageWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <BlockWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "42px" },
              p: { xs: "0 16px 36px", lg: "0 0 36px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  color: "#546272",
                  fontFamily: "Manrope",
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  lineHeight: { xs: "36px", md: "48px" },
                }}
              >
                Правила подготовки к диагностическим обследованиям
              </Typography>
            </Box>
            <BoxWrapper
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "8px", md: "12px" },
              }}
            >
              {rulesBlocks.map((block, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "4px", md: "8px" },
                  }}
                >
                  {block.title && (
                    <Typography
                      sx={{
                        color: "#546272",
                        fontFamily: "Manrope",
                        fontSize: { xs: "16px", md: "22px" },
                        fontWeight: 600,
                        lineHeight: { xs: "24px", md: "30px" },
                        textAlign: "justify",
                      }}
                    >
                      {block.title}
                    </Typography>
                  )}
                  {block.text &&
                    block.text.map((text, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textAlign: "justify",
                        }}
                      >
                        {text}
                      </Typography>
                    ))}
                  {block.underlinedText &&
                    block.underlinedText.map((text, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textDecoration: "underline",
                          textAlign: "justify",
                        }}
                      >
                        {text}
                      </Typography>
                    ))}
                  {block.links &&
                    block.links.map((link, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "14px", md: "20px" },
                          fontWeight: 400,
                          lineHeight: { xs: "22px", md: "28px" },
                          textIndent: "32px",
                          textAlign: "justify",
                        }}
                      >
                        {link}
                      </Typography>
                    ))}
                </Box>
              ))}
            </BoxWrapper>
          </Box>
        </BlockWrapper>
      </Box>
    </PageWrapper>
  );
};

export default Rules;
