import React from "react";
import { Box } from "@mui/material";

const BoxWrapper = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        p: { xs: "16px", md: "32px" },
        borderRadius: { xs: "16px", md: "24px" },
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        background: "#FFF",
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
};

export default BoxWrapper;
