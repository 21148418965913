export const specialistsData = [
  {
    id: 1,
    specialist: true,
    name: "Виктор",
    surname: "Кошлаков",
    patronymic: "Алексеевич",
    post: "Главный врач, врач-уролог, врач ультразвуковой диагностики",
    timeTable: "Понедельник, среда, пятница 16:30 – 19:00",
    diploma:
      "Саратовский государственный медицинский институт, Б-1 №500919, выдан 25.06.1977",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "Отличник здравоохранения Российской Федерации",
    certificate: [
      "Сертификат №1156242563082 урология, действителен до 15.11.2025",
      "Ультразвуковая диагностика, аккредитация, уникальный № реестровой записи 7723 031505653, действительна до 31.10.2028",
      "Сертификат №1156242323796, Организация здравоохранения и общественное здоровье, действителен до 27.03.2025",
    ],
  },
  {
    id: 2,
    specialist: true,
    name: "Игорь",
    surname: "Понятовский",
    patronymic: "Минович",
    post: "Врач-кардиолог",
    timeTable: "Вторник 16:00 – 19:00",
    diploma:
      "Военно-медицинский факультет при куйбышевском медицинском институте, РВ № 627097, выдан 21.06.1989",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "",
    certificate: [
      "Сертификат № 1177242553764 кардиология, действителен до 27.10.2025",
    ],
  },
  {
    id: 3,
    specialist: true,
    name: "Татьяна",
    surname: "Лазарева",
    patronymic: "Сергеевна",
    post: "Врач-эндокринолог",
    timeTable: "Понедельник 14:30 – 17:00",
    diploma:
      "Ростовский ордена дружбы народов медицинский институт, НВ №441736 Выдан 28.06.1986 г",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "Врач высшей категории",
    certificate: [
      "Эндокринология, аккредитация № реестровой записи 7722 026445082, действительна до 06.07.2027",
    ],
  },
  // {
  //   id: 4,
  //   specialist: true,
  //   name: "Виктория",
  //   surname: "Кисленко",
  //   patronymic: "Александровна",
  //   post: "Врач-акушер-гинеколог",
  //   timeTable: "Понедельник, четверг 17:00-19:00",
  //   diploma:
  //     "Диплом ФГБОУ высшего образования «Ростовский государственный медицинский университет» Министерства здравоохранения Российской Федерации 106124/3754963 Выдан 19.05.2018",
  //   specialize: "Лечебное дело",
  //   qualification: "Врач общей практики",
  //   category: "",
  //   certificate: [
  //     "Акушерство и гинекология, первичная специализированная аккредитация № реестровой записи 6120018346401 действительна до 12.12.2025",
  //   ],
  // },
  {
    id: 5,
    specialist: true,
    name: "Наталья",
    surname: "Лоцмер",
    patronymic: "Владимировна",
    post: "Врач-педиатр, врач ультразвуковой диагностики",
    timeTable: "Понедельник, среда 10:00 – 13:00",
    diploma:
      "ГБОУВПО Ростовский государственный медицинский институт, КО № 98634 выдан 22.06.2012 г.",
    specialize: "Педиатрия",
    qualification: "Врач",
    category: "",
    certificate: [
      "Ультразвуковая диагностика, аккредитация, уникальный № реестровой записи 7723 031490471, действительна до 31.10.2028",
      "Сертификат №0161242029581, педиатрия, действителен до 05.05.2024",
    ],
  },
  {
    id: 6,
    specialist: true,
    name: "Лариса",
    surname: "Калмыкова",
    patronymic: "Федоровна",
    post: "Врач-терапевт, врач ультразвуковой диагностики",
    timeTable: "Вторник, четверг, пятница 9:00 –12:00",
    diploma:
      "Ростовский ордена дружбы народов медицинский институт, РВ № 448784 выдан 25.06.1988",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "",
    certificate: [
      "Ультразвуковая диагностика, аккредитация, уникальный № реестровой записи 7723 030640483 действительна до 25.04.2028",
      "Сертификат № 1156242563083, терапия, действителен до 15.11.2025",
    ],
  },
  {
    id: 7,
    specialist: true,
    name: "Ольга",
    surname: "Грушкина",
    patronymic: "Валерьевна",
    post: "Врач-аллерголог-иммунолог",
    timeTable: "Вторник, четверг, пятница 9:00 – 12:00",
    diploma:
      "Ростовский ордена дружбы народов медицинский институт, МВ № 55201 выдан 29.06.1985",
    specialize: "Педиатрия",
    qualification: "Врач-педиатр",
    category: "",
    certificate: [
      "Аллергология и иммунология, аккредитация № реестровой записи 7722 023383095, действительна до 22.11.2027",
    ],
  },
  {
    id: 8,
    specialist: true,
    name: "Марина",
    surname: "Лемешко",
    patronymic: "Васильевна",
    post: "Врач-невролог",
    timeTable: "Вторник 15:00 – 18:00",
    diploma:
      "Ростовский ордена дружбы народов медицинский институт, УВ № 025599 выдан 22.06.1991",
    specialize: "Педиатрия",
    qualification: "Врач-педиатр",
    category: "",
    certificate: [
      "Сертификат № 1154242433989, неврология, действителен до 27.10.2025",
    ],
  },
  {
    id: 9,
    specialist: true,
    name: "Дмитрий",
    surname: "Григорьев",
    patronymic: "Владимирович",
    post: "Врач-уролог, врач ультразвуковой диагностики",
    timeTable: "Четверг 15:00 – 17:00, суббота 8:00 -11:00",
    diploma:
      "ГОУВПО Ростовский государственный медицинский институт, КА №21147 выдан 24.06.2011 г.",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "",
    certificate: [
      "Урология, аккредитация № реестровой записи 7723 026761640, действительна до 28.02.2028.",
      "Ультразвуковая диагностика, аккредитация, уникальный № реестровой записи 7723 031054047, действительна до 29.08.2028",
    ],
  },
  {
    id: 10,
    specialist: true,
    name: "Вадим",
    surname: "Егоров",
    patronymic: "Анатольевич",
    post: "Врач-кардиолог",
    timeTable: "Среда, 15:30 – 19:00",
    diploma:
      "Ростовский ордена дружбы народов медицинский институт, МВ № 552176 выдан 29.06.1985",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "",
    certificate: [
      "Кардиология, аккредитация, уникальный № реестровой записи 7723 031567891, действительна до 28.11.2028",
    ],
  },
  {
    id: 11,
    specialist: true,
    name: "Оксана",
    surname: "Письменскова",
    patronymic: "Александровна",
    post: "Врач-акушер-гинеколог, врач ультразвуковой диагностики",
    timeTable: "Среда 16:00-19:00, суббота 9:00 – 12:00",
    diploma:
      "ГОУВПО Саратовский государственный медицинский университет Федерального агентства по здравоохранению и социальному развитию,ВСБ № 0627116 выдан 18.06.2005 г.",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "Врач высшей категории по спeциальности акушерство и гинекология",
    certificate: [
      "Сертификат № 1118310652371, ультразвуковая диагностика, действителен до 23.11.2025",
      "Сертификат № 1118310652372, акушерство и гинекология, действителен до 26.10.2025",
    ],
  },
  {
    id: 12,
    specialist: true,
    name: "Алевтина",
    surname: "Кружалина",
    patronymic: "Анатольевна",
    post: "Врач-оторинолоринголог",
    timeTable: "Понедельник, вторник, среда, четверг, пятница 12:00 – 15:00",
    diploma:
      "Диплом Ленинградского ордена Красного знамени педиатрического медицинского института ВСВ/0385970 Выдан 28.06.1982",
    specialize: "Педиатрия",
    qualification: "Врач",
    category: "",
    certificate: [
      "Сертификат № 0161/040011701, Оториноларингология, действителен до 02.07.2025",
    ],
  },
  {
    id: 13,
    specialist: true,
    name: "Анастасия",
    surname: "Скоба",
    patronymic: "Андреевна",
    post: "Врач-дерматовенеролог",
    timeTable: "Вторник 17:00 – 19:00, суббота 10:00-12:00",
    diploma:
      "Диплом ФГБОУ высшего образования «Ростовский государственный медицинский университет» Министерства здравоохранения Российской Федерации № 106104/0061871, выдан 29.06.2020 г",
    specialize: "Педиатрия",
    qualification: "Врач-педиатр общей практики",
    category: "",
    certificate: [
      "Дерматовенерология, первичная специализированная аккредитация № реестровой записи 6122 018296659 действительна до 14.07.2027",
    ],
  },
  {
    id: 14,
    specialist: true,
    name: "Надер",
    surname: "Хаммудех",
    patronymic: "Таха Исса",
    post: "Врач-гастроэнтеролог",
    timeTable: "Пятница 15:00 – 18:00",
    diploma:
      "Ростовский государственный медицинский институт, ДИ №036377, выдан 26.06.1976",
    specialize: "Лечебное дело",
    qualification: "Врач",
    category: "Кандидат медицинских наук",
    certificate: [
      "Сертификат № 1156242563081, гастроэнтерология, действителен до 15.11.2025",
    ],
  },
  // {
  //   id: 15,
  //   specialist: false,
  //   name: "Мария",
  //   surname: "Литовская",
  //   patronymic: "Федоровна",
  //   post: "Врач-гинеколог",
  //   timeTable: "",
  //   diploma:
  //     "Ростовский государственный медицинский институт, ЕВ №167749, выдан 26.06.1980",
  //   specialize: "Лечебное дело",
  //   qualification: "Врач",
  //   category: "",
  //   certificate: [
  //     "Сертификат № 1177242151823, акушерство и гинекология, действителен до 15.10.2024",
  //   ],
  // },
  {
    id: 16,
    specialist: false,
    name: "Светлана",
    surname: "Карпова",
    patronymic: "Викторовна",
    post: "Медицинская сестра",
    timeTable: "",
    diploma:
      "Новочеркасское медицинское училище Диплом ЕТ №786561 Выдан 28.02.1986",
    specialize: "Сестринское дело",
    qualification: "Медицинская сестра",
    category: "",
    certificate: [
      "Сертификат №1177242244689, сестринское дело, действителен до 23.12.2024",
    ],
  },
  {
    id: 17,
    specialist: false,
    name: "Мария",
    surname: "Полякова",
    patronymic: "Сергеевна",
    post: "Медицинская сестра",
    timeTable: "",
    diploma:
      'Государственное бюджетное профессиональное образовательное учреждение Ростовской области "Новочеркасский медицинский колледж"',
    specialize: "Сестринское дело",
    qualification: "Медицинская сестра/ медицинский брат",
    category: "",
    certificate: [
      "Сестринское дело, первичная аккредитация № реестровой записи 6123 030780809 действительна до 18.07.2028",
    ],
  },
];
