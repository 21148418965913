import React from "react";
import { Box, CardMedia, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ImageDialog = ({ open, handleClose, image }) => {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          background: "rgba(84, 98, 114, 0.15)",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt="dialogImage"
          sx={{
            maxHeight: { xs: "400px", md: "800px" },
            maxWidth: { xs: "300px", md: "600px" },
            width: "auto",
            height: "auto",
            m: "auto",
          }}
        />
        <Box sx={{ position: "absolute", right: 0, top: 0, p: "8px" }}>
          <Box
            onClick={handleClose}
            sx={{
              borderRadius: "50%",
              p: "4px",
              display: "flex",
              ":hover": {
                background: "rgba(84, 98, 114, 0.25)",
              },
            }}
          >
            <CloseIcon
              sx={{ color: "#546272", fontSize: "24px", cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ImageDialog;
