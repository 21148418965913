import Review1 from "../../assets/reviews/ko1.jpg";
import Review2 from "../../assets/reviews/ko2.jpg";
import Review3 from "../../assets/reviews/ko3.jpg";
import Review4 from "../../assets/reviews/ko4.jpg";
import Review5 from "../../assets/reviews/ko5.jpg";
import Review6 from "../../assets/reviews/ko6.jpg";
import Review7 from "../../assets/reviews/ko7.jpg";
import Review8 from "../../assets/reviews/ko8.jpg";
import Review9 from "../../assets/reviews/ko9.jpg";
import Review10 from "../../assets/reviews/ko10.jpg";
import Review11 from "../../assets/reviews/ko11.jpg";
import Review12 from "../../assets/reviews/ko12.jpg";
import Review19 from "../../assets/reviews/ko19.jpg";

import License1 from "../../assets/documents/licence1-2018-2.jpg";
import License2 from "../../assets/documents/licence2-2018-2.jpg";
import License3 from "../../assets/documents/licence3-2018-2.jpg";
import License4 from "../../assets/documents/licence4-2018-2.jpg";

export const reviews = [
  Review1,
  Review2,
  Review3,
  Review4,
  Review5,
  Review6,
  Review7,
  Review8,
  Review9,
  Review10,
  Review11,
  Review12,
  Review19,
];

export const documents = [License1, License2, License3, License4];
