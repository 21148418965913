import { Box } from "@mui/material";
import NavBar from "./navBar";
import Footer from "../footer";
import MobileNavBar from "./mobileNavBar";
import { useState } from "react";

export const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        background: "linear-gradient(330deg, #E7E1E6 0%, #F9FAFF 30%)",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <MobileNavBar
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
      {!open && (
        <>
          <NavBar />
          <Box sx={{ width: "-webkit-fill-available" }}>{children}</Box>
          <Footer />
        </>
      )}
    </Box>
  );
};
