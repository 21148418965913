import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { phone, timeTableList } from "../../../common/constants";
import YMap from "../../common/map";
import BlockWrapper from "../../common/blockWrapper";
import BoxWrapper from "../../common/boxWrapper";

const Contacts = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <BlockWrapper>
        <Box
          sx={{
            p: { xs: "32px 16px", md: "56px 16px", lg: "56px 0" },
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", md: "42px" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                color: "#546272",
                fontFamily: "Manrope",
                fontSize: { xs: "24px", md: "32px" },
                fontWeight: 600,
                lineHeight: { xs: "36px", md: "48px" },
              }}
            >
              Контакты
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: { xs: "16px", md: "24px" },
                    background: "#E7E1E0",
                    boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
                    height: "100%",
                    minHeight: "284px",
                  }}
                >
                  <YMap />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: "16px", md: "32px" },
                  }}
                >
                  <BoxWrapper>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { xs: "12px", md: "18px" },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "24px", md: "32px" },
                          fontWeight: 600,
                          lineHeight: { xs: "36px", md: "48px" },
                        }}
                      >
                        Профи-клиника
                      </Typography>
                      <Typography
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "16px", md: "20px" },
                          fontWeight: 600,
                          lineHeight: { xs: "24px", md: "28px" },
                        }}
                      >
                        г. Новочеркасск, пр. Ермака, 99
                      </Typography>
                      {timeTableList.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: { xs: "4px", md: "8px" },
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#546272",
                              fontFamily: "Manrope",
                              fontSize: { xs: "16px", md: "22px" },
                              fontWeight: 400,
                              lineHeight: { xs: "24px", md: "30px" },
                            }}
                          >
                            {item.title}
                          </Typography>
                          {item.fields.map((field, index) => (
                            <Typography
                              key={index}
                              sx={{
                                color: "#546272",
                                fontFamily: "Manrope",
                                fontSize: { xs: "12px", md: "16px" },
                                fontWeight: 300,
                                lineHeight: { xs: "20px", md: "24px" },
                              }}
                            >
                              {field}
                            </Typography>
                          ))}
                        </Box>
                      ))}
                    </Box>
                  </BoxWrapper>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: { xs: "16px", md: "32px" },
                      flexDirection: { xs: "column", xl: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        p: { xs: "6px", md: "12px" },
                        borderRadius: { xs: "16px", md: "24px" },
                        background: "#FFF",
                        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "center",
                        gap: { xs: "4px", md: "8px" },
                      }}
                    >
                      <Box sx={{ display: "flex", m: "auto 0" }}>
                        <PhoneIcon sx={{ color: "#546272" }} />
                      </Box>
                      <Typography
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "12px", md: "18px" },
                          fontWeight: 600,
                          lineHeight: { xs: "20px", md: "26px" },
                          textAlign: "center",
                          m: "auto 0",
                        }}
                      >
                        8 (8635) 22-35-61
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: { xs: "6px", md: "12px" },
                        borderRadius: { xs: "16px", md: "24px" },
                        background: "#FFF",
                        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "center",
                        gap: { xs: "4px", md: "8px" },
                      }}
                    >
                      <Box sx={{ display: "flex", m: "auto 0" }}>
                        <PhoneIcon sx={{ color: "#546272" }} />
                      </Box>
                      <Typography
                        sx={{
                          color: "#546272",
                          fontFamily: "Manrope",
                          fontSize: { xs: "12px", md: "18px" },
                          fontWeight: 600,
                          lineHeight: { xs: "20px", md: "26px" },
                          textAlign: "center",
                        }}
                      >
                        {phone}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </BlockWrapper>
    </Box>
  );
};

export default Contacts;
