import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { placemark } from "../../common/constants";

const YMap = () => {
  return (
    <YMaps
      query={{
        load: "Map,Placemark,control.ZoomControl,geoObject.addon.balloon,geoObject.addon.hint",
      }}
    >
      <Map
        defaultState={{
          center: [47.4194, 40.0984],
          zoom: 15,
          controls: ["zoomControl"],
        }}
        width="100%"
        height="100%"
      >
        <Placemark
          options={{
            preset: "islands#circleIcon", // список темплейтов на сайте яндекса
            iconColor: "green", // цвет иконки, можно также задавать в hex
            iconLayout: "default#image",
            iconImageHref: "images/hospital.png", // Своё изображение иконки метки.
            iconImageSize: [40, 40], // Размеры метки.
            iconImageOffset: [-10, -10], // Смещение левого верхнего угла иконки относительно её "ножки" (точки привязки).
            hideIconOnBalloonOpen: false, // не скрываем метку на карте при открытии балуна
          }}
          defaultGeometry={placemark.coords}
          properties={{
            iconContent: `${placemark.id}`,
            hintContent: `<b> ${placemark.name} </b>`,
            balloonContentBody:
              ` <div className="item-place-title">${placemark.name}</div>` +
              `<div className="item-place-address">${placemark.address}</div>`,
          }}
          onClick={() => {
            console.log(`Click balloon ${placemark.name}`);
          }}
        />
      </Map>
    </YMaps>
  );
};

export default YMap;
