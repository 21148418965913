import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const ProfileOpenBlock = ({ children, blockName, open = true, ...props }) => {
  return (
    <Accordion
      {...props}
      defaultExpanded={open}
      disableGutters
      sx={{
        mt: 0,
        p: { xs: "16px", md: "24px" },
        background: "#FFF",
        borderRadius: { xs: "16px", md: "24px" },
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        "::before": {
          content: "none",
        },
        ...props.sx,
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          minHeight: "unset",
          ".MuiAccordionSummary-content": { m: 0 },
        }}
        expandIcon={
          <ArrowDropDownIcon
            sx={{
              color: "#5CADCB",
            }}
          />
        }
      >
        <Typography
          sx={{
            color: "#546272",
            fontFamily: "Manrope",
            fontSize: { xs: "18px", md: "22px" },
            fontWeight: 600,
            lineHeight: { xs: "26px", md: "30px" },
          }}
        >
          {blockName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, mt: 3, borderTop: "1px solid #EBEBEB" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ProfileOpenBlock;
