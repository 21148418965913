import React, { useState } from "react";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "../../common/utils/fn";

const SearchField = ({
  value = "",
  handleSearch,
  min = 0,
  fullWidth = false,
}) => {
  const [valSearch, setValSearch] = useState(value);
  const onHandleChange = (val) => {
    setValSearch(val);
    debounce(() => {
      if (val.length === 0) return handleSearch("");
      if (val.length > min) handleSearch(val);
    }, 500);
  };

  return (
    <TextField
      fullWidth={fullWidth}
      sx={{
        background: "#fff",
        width: !fullWidth ? "200px" : undefined,
      }}
      placeholder="Search"
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: "14px" }} />,
      }}
      value={valSearch}
      onChange={(e) => onHandleChange(e.target.value)}
    />
  );
};

export default SearchField;
