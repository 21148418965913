export * from "./home"
export * from "./images"
export * from "./medicalActivities"
export * from "./organizationInfo"
export * from "./patientsInformation"
export * from "./rules"
export * from "./services"

export const phone = "+7 (961) 324-78-66";
export const phone2 = "8 (8635) 22-35-61";

export const navigation = [
  {
    text: "Главная",
    link: "/",
  },
  // {
  //   text: "О нас",
  //   link: "/about",
  // },
  {
    text: "Специалисты",
    link: "/specialists",
  },
  {
    text: "Услуги",
    link: "/services",
  },
  {
    text: "Контакты",
    link: "/contacts",
  },
];

export const usefulLinks = [
  {
    name: "Медицинская деятельность",
    link: "/medical-activities",
  },
  {
    name: "Сведения об организации",
    link: "/organization-info",
  },
  {
    name: "Информация для пациентов",
    link: "/patients-information",
  },
  {
    name: "Правила подготовки к диагностическим обследованиям",
    link: "/rules",
  },
  {
    name: "Сведения о медицинских работниках ООО «Профи-клиника»",
    link: "/specialists-info",
  },
];

export const placemark = {
  id: 1,
  name: "ООО «Профи-клиника»",
  coords: [47.4194, 40.0984],
  address: "г. Новочеркасск, пр. Ермака, 99",
};
