import { Box, Typography } from "@mui/material";
import React from "react";
import NavBarButton from "./components/navBarButtotn";
import { navigation, phone, phone2 } from "../../common/constants";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BlockWrapper from "../common/blockWrapper";

const MobileNavBar = ({ open, handleOpen, handleClose }) => {
  const { pathname } = useLocation();

  const getSelected = (link) =>
    (pathname === "/" && link === "/") ||
    (link !== "/" && pathname.includes(link, 0));

  return (
    <Box
      sx={{
        background: "#FFF",
        boxShadow: "0px 0px 16px 0px rgba(230, 230, 230, 0.50)",
        display: { xs: "flex", md: "none" },
        justifyContent: "center",
        width: "-webkit-fill-available",
      }}
    >
      {open ? (
        <Box
          sx={{
            maxWidth: "100%",
            width: "-webkit-fill-available",
            height: "100vh",
            background: "rgba(0,0,0, 0.5)",
            display: "flex",
          }}
        >
          <Box
            sx={{
              background: "#FFF",
              width: "80%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: "16px ",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    m: "auto 0",
                  }}
                >
                  {phone}
                </Typography>
                <Typography
                  sx={{
                    color: "#546272",
                    fontFamily: "Manrope",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "27px",
                    m: "auto 0",
                  }}
                >
                  {phone2}
                </Typography>
              </Box>
              <Box
                onClick={handleClose}
                sx={{
                  border: "1px solid #9A8891",
                  display: "flex",
                  borderRadius: "8px",
                  p: "4px",
                  margin: "auto 0"
                }}
              >
                <ArrowBackIcon sx={{ color: "#546272" }} />
              </Box>
            </Box>
            <Box
              sx={{ height: "1px", opacity: "0.25", background: "#546272" }}
            ></Box>
            <Box
              onClick={handleClose}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                p: "8px 0",
              }}
            >
              {navigation.map((item, index) => (
                <NavBarButton
                  key={index}
                  text={item.text}
                  link={item.link}
                  selected={getSelected(item.link)}
                />
              ))}
            </Box>
          </Box>
          <Box
            onClick={handleClose}
            sx={{
              width: "20%",
              height: "100%",
              display: "flex",
            }}
          ></Box>
        </Box>
      ) : (
        <BlockWrapper>
          <Box
            sx={{ p: "16px", display: "flex", justifyContent: "space-between" }}
          >
            <Box
              onClick={handleOpen}
              sx={{
                border: "1px solid #9A8891",
                display: "flex",
                borderRadius: "8px",
                p: "4px",
              }}
            >
              <MenuIcon sx={{ color: "#9A8891" }} />
            </Box>
            <Typography
              sx={{
                color: "#9A8891",
                fontFamily: "Manrope",
                fontSize: "22px",
                fontWeight: 600,
                lineHeight: "30px",
                m: "auto 0",
              }}
            >
              Профи-клиника
            </Typography>
          </Box>
          <Box
            sx={{ height: "1px", opacity: "0.25", background: "#546272" }}
          ></Box>
        </BlockWrapper>
      )}
    </Box>
  );
};

export default MobileNavBar;
